import { makeStyles } from '@material-ui/styles'

import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    width: '100%',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
    padding: spacing(24),
    alignItems: 'center',
    borderBottom: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
  },
  item: {
    height: spacing(40),
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: spacing(8),
    position: 'relative',
  },
  input: {
    '& .MuiOutlinedInput-input': {
      padding: spacing(10, 12),
      fontSize: spacing(14),
      lineHeight: spacing(20),
      color: Theme.Colors.Grayscale.Eighty,
    },
  },
  moneyInput: {
    border: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
    padding: spacing(4, 8),
    borderRadius: spacing(4),
  },
  label: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: Theme.Colors.Grayscale.Eighty,
  },
  value: {
    fontSize: spacing(16),
    lineHeight: spacing(20),
    padding: spacing(10, 0),
    color: Theme.Colors.Grayscale.Eighty,
  },
  totalWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing(16),
    padding: spacing(16, 24, 0, 0),
  },
  totalField: {
    width: spacing(144),
  },
  totalLabel: {
    color: Theme.Colors.Grayscale.SixtyFour,
    marginBottom: spacing(4),
  },
  blackValue: {
    color: Theme.Colors.Grayscale.Eighty,
  },
  blueValue: {
    color: Theme.Colors.Primary.Base,
  },
  greenValue: {
    color: Theme.Colors.Green.Base,
  },
  redValue: {
    color: Theme.Colors.Red.Base,
  },
  medium: {
    fontWeight: 500,
    fontSize: spacing(16),
  },
  referaTaxButton: {
    position: 'absolute',
    width: spacing(44),
    height: spacing(44),
    right: spacing(-52),
    bottom: spacing(6),
  },
}))
