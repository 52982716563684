import { makeStyles } from '@material-ui/styles'

import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  dialog: {
    position: 'absolute',
    minHeight: spacing(600),
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  title: {
    color: '#4169e1',
    cursor: 'grab',

    '&:active': {
      cursor: 'grabbing',
    },
  },
  content: {
    width: spacing(600),
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(8),
    padding: spacing(12, 0),
    margin: spacing(0, 24),
    alignItems: 'center',
  },
  item: {
    height: spacing(40),
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: spacing(8),
    position: 'relative',
  },
  input: {
    '& .MuiInputBase-input': {
      textAlign: 'right',
    },
  },
  moneyInput: {
    border: `1px solid ${Theme.Colors.Grayscale.Twelve}`,
    padding: spacing(4, 8),
    borderRadius: spacing(4),
  },
  readField: {
    textAlign: 'right',
    fontSize: spacing(16),
    lineHeight: spacing(20),
    color: Theme.Colors.Grayscale.OneThousand,
  },
  black: {
    color: 'black !important',
  },
  bold: {
    fontWeight: 'bold',
  },
  label: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    color: Theme.Colors.Grayscale.Eighty,
  },
  value: {
    fontSize: spacing(16),
    lineHeight: spacing(20),
    padding: spacing(10, 0),
    color: Theme.Colors.Grayscale.Eighty,
    textAlign: 'right',
  },
  headerWrapper: {
    display: 'flex',
    gap: spacing(16),
    alignItems: 'center',
    justifyContent: 'center',
    margin: spacing(4, 0, 8),
  },
  soloItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  soloItemValue: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  finalPricesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
    margin: spacing(0, 24),
    alignItems: 'center',
  },
  finalItem: {
    width: '100%',
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(12, 0, 0),
  },
  dialogActions: {
    padding: '16px 20px',
  },
}))
