import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Link, IconButton, Tooltip } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import { Link as RouterLink, useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import EditIcon from '_assets/icons/ic-edit.svg'
import Svg, { ICON } from '_components/svg'
import Button from '_components/button'
import useRolePermission from '_hooks/use-role-permission'
import { getBudgetByIdSelector } from '_modules/budget/selectors'
import { SendBudgetByEmailButton, SendBudgetByWhatsAppButton } from '_components/service-order'
import ModalDialog from '_components/modal/modal-dialog'
import { Loader } from '@refera/ui-web'

import ModalConfirmInstallment from '../components/ModalConfirmInstallment'

import useStyles from './styles'
import { getVerifyBudgetFinancialValues } from '_/modules/budget/actions'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const Actions = ({
  isCancelled,
  serviceOrderId,
  onToggleEdit,
  isEdit,
  isOriginalBudget,
  onCancelEditClick,
  onBackToServiceOrderClick,
  onSaveClick,
  isSavingLoading,
}) => {
  const styles = useStyles({ isCancelled })
  const dispatch = useDispatch()
  const { budgetId } = useParams()
  const budget = useSelector(getBudgetByIdSelector(budgetId))
  const { checkUserPermission, isSAAS, isAdmin, isIntermediary } = useRolePermission()
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const { showToast } = useToast()

  // TODO: Remove this once BE send this info
  const originalBudgetId = isOriginalBudget ? undefined : budget.budgetParent
  const editableBudgetId = isOriginalBudget ? Number(budgetId) + 1 : undefined
  const [modalOpen, setModalOpen] = useState(false)
  const [isValidatingBudget, setIsValidatingBudget] = useState(false)

  const isLoading = useMemo(() => isValidatingBudget, [isValidatingBudget])

  const backToServiceOrderButtonProps = useMemo(() => {
    if (isEdit) {
      return {
        onClick: onBackToServiceOrderClick,
      }
    }

    return {
      to: `/chamado/${serviceOrderId}`,
    }
  }, [onBackToServiceOrderClick, isEdit, serviceOrderId])

  const handleValidBudget = useCallback(() => {
    setIsValidatingBudget(true)
    dispatch(getVerifyBudgetFinancialValues(budgetId))
      .then(() => setModalOpen(true))
      .catch(error => {
        const message = formatErrorMessage(error)
        showToast({ message, type: 'error' })
      })
      .finally(() => {
        setIsValidatingBudget(false)
      })
  }, [dispatch, budgetId])

  const showSendBudgetByEmailButton = useMemo(
    () => isIntermediary || isAdmin,
    [isEdit, isIntermediary, isAdmin]
  )

  return (
    <>
      <Loader hasBackdrop open={isLoading} />
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        className={styles.header}
      >
        {isOriginalBudget ? (
          <Button
            to={`/chamado/${serviceOrderId}/orcamento/${editableBudgetId}`}
            color="secondary"
            startIcon={<ArrowBackIcon className={styles.buttonIcon} />}
          >
            Voltar ao orçamento
          </Button>
        ) : (
          <Button
            color="secondary"
            startIcon={<ArrowBackIcon className={styles.buttonIcon} />}
            {...backToServiceOrderButtonProps}
          >
            Voltar ao pedido
          </Button>
        )}
        <Grid className={styles.headerButtons}>
          {!isOriginalBudget && (
            <Grid className={styles.buttons}>
              {isEdit && (isSAAS || isAdmin) ? (
                <>
                  <Button
                    disabled={isSavingLoading}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      checkUserPermission({
                        permission: PERMISSIONS.SERVICE_ORDER,
                        permissionAction: [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT],
                        functionAccess: () => onSaveClick(),
                      })
                    }
                  >
                    Salvar
                  </Button>
                  <Button variant="outlined" onClick={onCancelEditClick}>
                    Cancelar
                  </Button>
                </>
              ) : (
                <>
                  {(!budget?.proposeOpened || budget?.tradesman) && (
                    <Link
                      component={RouterLink}
                      to={`/chamado/${serviceOrderId}/orcamento/${originalBudgetId}`}
                      className={styles.originalBudgetLink}
                    >
                      Orçamento original
                    </Link>
                  )}
                  {showSendBudgetByEmailButton && <SendBudgetByEmailButton />}
                  <SendBudgetByWhatsAppButton />
                  <Tooltip title="Baixar PDF">
                    <IconButton
                      onClick={handleValidBudget}
                      className={styles.downloadButton}
                      disabled={isLoading}
                    >
                      <Svg type={ICON.DOWNLOAD} />
                    </IconButton>
                  </Tooltip>
                  {modalOpen && (
                    <ModalConfirmInstallment
                      open={modalOpen}
                      onCancel={() => setModalOpen(false)}
                      serviceOrderId={serviceOrderId}
                      budgetId={budgetId}
                    />
                  )}
                  {(isAdmin || isSAAS) && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        checkUserPermission({
                          permission: PERMISSIONS.BUDGETS,
                          permissionAction: [PERMISSIONS_ACTIONS.ADD, PERMISSIONS_ACTIONS.EDIT],
                          functionAccess: () => onToggleEdit(),
                        })
                      }
                      endIcon={<Svg icon={EditIcon} className={styles.editIcon} />}
                    >
                      Editar
                    </Button>
                  )}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
    </>
  )
}

Actions.propTypes = {
  isCancelled: PropTypes.bool,
  serviceOrderId: PropTypes.number.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isSavingLoading: PropTypes.bool,
  onToggleEdit: PropTypes.func.isRequired,
  isOriginalBudget: PropTypes.bool.isRequired,
  onCancelEditClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  // TODO: Remove eslint after BE sends this info
  originalBudgetId: PropTypes.number, // eslint-disable-line
  editableBudgetId: PropTypes.number, // eslint-disable-line
  onBackToServiceOrderClick: PropTypes.func.isRequired,
  onDownloadPdfClick: PropTypes.func,
  isDownloadPdfDialogOpen: PropTypes.func,
}

Actions.defaultProps = {
  isCancelled: false,
  originalBudgetId: undefined,
  editableBudgetId: undefined,
  onDownloadPdfClick: undefined,
  isDownloadPdfDialogOpen: undefined,
  isSavingLoading: false,
}

export default React.memo(Actions)
