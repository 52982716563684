/* eslint-disable react/no-unescaped-entities */
import React from 'react'

export const items = ({ budget }) => {
  return [
    {
      label: 'Tarifa regular',
      fields: [
        {
          type: 'percentage-input',
          name: 'referaRegularTaxPercentage',
          value: budget?.referaRegularTaxPercentage,
          complementName: 'referaRegularTax',
        },
        {
          type: 'currency-input',
          name: 'referaRegularTax',
          value: budget?.referaRegularTax,
          complementName: 'referaRegularTaxPercentage',
        },
      ],
    },
    {
      label: 'Tarifa dinâmica',
      fields: [
        {
          type: 'percentage-input',
          name: 'additionalTakeRatePerc',
          value: budget?.additionalTakeRatePerc,
          complementName: 'additionalTakeRate',
        },
        {
          type: 'currency-input',
          name: 'additionalTakeRate',
          value: budget?.additionalTakeRate,
          complementName: 'additionalTakeRatePerc',
        },
      ],
    },
    {
      label: 'Garantia',
      fields: [
        {
          type: 'currency-input',
          name: 'warrantyValue',
          value: budget?.warrantyValue,
          complementName: null,
        },
      ],
    },
    {
      label: 'Revenue share',
      fields: [
        {
          type: 'currency-read',
          name: 'revenueShare',
          value: budget?.revenueShare,
          complementName: null,
        },
      ],
    },
  ]
}

export const VISUALIZATION_TOAST_MESSAGE = (
  <span>
    Para editar as tarifas da refera, primeiro clique em "Editar" na tela do orçamento.
    <br />
    Assim, você terá acesso ao modo de edição dos valores.
  </span>
)

export const APPLY_TOAST_MESSAGE =
  'Para efetivar suas alterações sobre as tarifas da refera no orçamento, você precisa salvar o orçamento.'
