import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import classnames from 'classnames'
import Theme from '@refera/ui-core'
import { MoneyInput, PercentInput } from '@refera/ui-web'

import useRolePermission from '_hooks/use-role-permission'

import useStyles from './styles'
import { round } from '_/views/finance/utils/functions'
import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { useSelector } from 'react-redux'
import IconButton from '_/components/svg/icon-button'
import { ReferaTaxesModal } from '../refera-taxes-modal'
import { displayCurrency, displayPercentage } from '../utils'

const PriceSection = ({ budget, handleInputChange, disabled, isEdit }) => {
  const styles = useStyles()
  const { isAdmin, isSAAS } = useRolePermission()
  const [inputValue, setInputValue] = useState()
  const [inputPercentage, setInputPercentage] = useState()
  const [referaTaxesModalOpen, setReferaTaxesModalOpen] = useState(false)
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const isFromSAASAgencyAndNotReferaService = useMemo(() => {
    const parsedServiceOrder = serviceOrder?.toJS()
    return (
      parsedServiceOrder?.isReferaService === false &&
      parsedServiceOrder?.agency?.contractType === 'SAAS'
    )
  }, [serviceOrder])

  const itemsList = useMemo(() => {
    let adminOnlyItems = [
      {
        label: 'Total prestador',
        value: budget.totalPriceTradesman,
        name: 'totalPriceTradesman',
        type: 'read',
        style: styles.value,
        totalReference: null,
      },
      {
        label: 'Tarifa da intermediária',
        value: budget.agencyCompletionPercentage,
        name: 'agencyCompletionPercentage',
        type: 'input',
        style: null,
        totalReference: budget.totalPriceTradesman,
      },
      {
        label: 'Tarifa da refera',
        value: budget.referaCompletionPercentage,
        name: 'referaCompletionPercentage',
        type: 'input',
        style: null,
        totalReference: budget.totalPriceTradesman,
        disabled: true,
      },
      {
        label: 'Receita Financeira',
        value: budget.financialIncomeValue,
        name: 'financialIncomeValue',
        type: 'read',
        style: styles.value,
        totalReference: budget.totalPriceTradesman,
      },
    ]

    if (isFromSAASAgencyAndNotReferaService) {
      adminOnlyItems = adminOnlyItems.filter(item => item.name !== 'referaCompletionPercentage')
    }

    const defaultItems = [
      {
        label: 'Valor bruto',
        value: budget?.totalPrice,
        name: 'totalPrice',
        type: 'read',
        style: classnames(styles.value, styles.greenValue, styles.medium),
        totalReference: null,
      },
      {
        label: 'Imposto retido',
        value: `${budget?.withholdingTax || 0}%`,
        name: 'withholdingTax',
        type: 'input',
        style: classnames(styles.value, styles.redValue),
        totalReference: budget?.totalPrice,
      },
    ]

    if (isAdmin || isSAAS) {
      return [...adminOnlyItems, ...defaultItems]
    }

    return defaultItems
  }, [budget, isAdmin, isSAAS, isFromSAASAgencyAndNotReferaService])

  const handleMoneyInputStyle = item => {
    if (item?.name === 'withholdingTax') {
      return { color: Theme.Colors.Red.Base, fontSize: '16px' }
    }
    return { fontSize: '16px' }
  }

  const handleChangeValues = useCallback(
    (item, type) => {
      let percentageValue = 0
      let input = 0
      let percentageEvent
      let valueEvent

      if (type === 'value') {
        if (inputValue?.floatValue) {
          percentageValue = (inputValue?.floatValue / item.totalReference) * 100 || 0
          input = inputValue?.floatValue
        }

        if (
          inputValue?.item?.name === item.name &&
          input !== Number(parseFloat(budget[`${item.name}Value`])?.toFixed(2))
        ) {
          percentageEvent = { target: { name: item.name, value: round(percentageValue, 4) } }

          valueEvent = {
            target: { name: `${item.name}Value`, value: round(input, 2) || 0 },
          }
        }
      }
      if (type === 'percentage') {
        if (inputPercentage?.floatValue) {
          percentageValue = (inputPercentage?.floatValue / 100.0) * item.totalReference || 0
          input = inputPercentage?.floatValue
        }

        if (
          inputPercentage?.item?.name === item.name &&
          input !== Number(parseFloat(budget[item.name])?.toFixed(2))
        ) {
          percentageEvent = {
            target: { name: `${item.name}Value`, value: round(percentageValue, 2) },
          }

          valueEvent = {
            target: {
              name: item.name,
              value: Number(round(input, 4)) || 0,
            },
          }
        }
      }

      if (percentageEvent && valueEvent) {
        handleInputChange(percentageEvent)
        handleInputChange(valueEvent)
      }
    },
    [handleInputChange, budget, inputValue, inputPercentage]
  )

  const handleFinancialIncomeAndTotalPrice = useCallback(() => {
    const totalPrice =
      budget?.totalPriceTradesman +
      budget?.agencyCompletionPercentageValue +
      budget?.referaCompletionPercentageValue

    const newFinancialIncomePrice = round((totalPrice * budget?.originalFinancialIncome) / 100, 2)

    handleInputChange({ target: { name: 'financialIncomeValue', value: newFinancialIncomePrice } })

    const newTotalPrice = round(Number(totalPrice + newFinancialIncomePrice), 2)
    handleInputChange({ target: { name: 'totalPrice', value: newTotalPrice } })

    const newWithholdingTaxValue = round(newTotalPrice * (budget?.withholdingTax / 100.0), 2)
    handleInputChange({ target: { name: 'withholdingTaxValue', value: newWithholdingTaxValue } })
  }, [budget])

  const handleFinalPrices = useCallback(() => {
    const totalPriceServiceProvider = {
      target: {
        name: 'totalPriceServiceProvider',
        value: round(budget?.totalPriceTradesman - budget?.withholdingTaxValue, 2) || 0,
      },
    }
    handleInputChange(totalPriceServiceProvider)

    const liquidValue = {
      target: {
        name: 'liquidValue',
        value: round(budget?.totalPrice - budget?.withholdingTaxValue, 2) || 0,
      },
    }
    handleInputChange(liquidValue)
  }, [budget])

  useEffect(() => {
    handleFinancialIncomeAndTotalPrice()
  }, [
    budget?.totalPriceTradesman,
    budget?.agencyCompletionPercentageValue,
    budget?.referaCompletionPercentageValue,
  ])

  useEffect(() => {
    handleFinalPrices()
  }, [budget?.totalPrice, budget?.withholdingTaxValue, budget?.financialIncomeValue])

  useEffect(() => {
    // if is from SAAS agency and not refera service, set referaCompletionPercentage and referaCompletionPercentageValue to 0
    if (isFromSAASAgencyAndNotReferaService) {
      handleInputChange({ target: { name: 'referaCompletionPercentage', value: 0 } })
      handleInputChange({ target: { name: 'referaCompletionPercentageValue', value: 0 } })
    }
  }, [isFromSAASAgencyAndNotReferaService])

  return (
    <Grid className={styles.container}>
      <Grid className={styles.itemsContainer}>
        {itemsList?.map(item => (
          <Grid className={styles.item} key={item.label}>
            <Typography className={styles.label}>{item?.label}</Typography>
            {item?.type === 'input' && (
              <>
                <PercentInput
                  value={displayPercentage(budget[item?.name])}
                  mask=" "
                  format="####"
                  placeholder="%"
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onBlur={() => handleChangeValues(item, 'percentage')}
                  onChange={({ floatValue }) => setInputPercentage({ floatValue, item })}
                  style={{ fontSize: '16px' }}
                  disabled={disabled || item?.disabled}
                />
                <MoneyInput
                  value={displayCurrency(budget[`${item?.name}Value`])}
                  style={handleMoneyInputStyle(item)}
                  placeholder="R$ "
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onBlur={() => handleChangeValues(item, 'value')}
                  onChange={({ floatValue }) => setInputValue({ floatValue, item })}
                  disabled={disabled || item?.disabled}
                />
              </>
            )}
            {item?.type === 'read' && (
              <>
                <Grid />
                <Typography className={item?.style}>
                  {displayCurrency(budget[item?.name])}
                </Typography>
              </>
            )}
            {item?.name === 'referaCompletionPercentage' && (
              <IconButton
                onClick={() => setReferaTaxesModalOpen(true)}
                className={styles.referaTaxButton}
              >
                <HelpOutlineOutlinedIcon className={styles.tooltipIcon} />
              </IconButton>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid className={styles.totalWrapper}>
        {(isAdmin || isSAAS) && (
          <Grid>
            <Typography className={styles.totalLabel}>Valor final do prestador</Typography>
            <Typography className={classnames(styles.blackValue, styles.medium)}>
              {displayCurrency(budget?.totalPriceServiceProvider)}
            </Typography>
          </Grid>
        )}
        <Grid className={styles.totalField}>
          <Typography className={styles.totalLabel}>Valor líquido</Typography>
          <Typography className={classnames(styles.blueValue, styles.medium)}>
            {displayCurrency(budget?.liquidValue)}
          </Typography>
        </Grid>
      </Grid>
      {referaTaxesModalOpen && (
        <ReferaTaxesModal
          open={referaTaxesModalOpen}
          budget={budget}
          handleInputChange={handleInputChange}
          close={() => setReferaTaxesModalOpen(false)}
          isVisualization={!isEdit}
        />
      )}
    </Grid>
  )
}

export default PriceSection
