export function displayCurrency(value) {
  return value
    ? value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
    : 'R$ 0,00'
}

export function displayPercentage(value) {
  return value
    ? (value / 100).toLocaleString('pt-br', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '0,00%'
}
