import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  page: {
    color: 'black',
    display: 'flex',
    margin: '16px 10px',
    paddingBottom: '70px',
  },
  section: {
    display: 'flex',
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    height: '60px',
    width: '100%',
    margin: '-16px -10px 16px',
    backgroundColor: '#1671ED',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  headerLogo: {
    height: '30px',
    marginLeft: '10px',
  },
  budgetId: {
    marginRight: '10px',
    color: 'white',
  },
  budgetContainer: {
    fontSize: '12px',
    margin: '24px 0',
  },
  input: {
    display: 'flex',
    border: '1px solid rgba(180, 180, 180, 1)',
    borderRadius: '4px',
    margin: '10px 0 12px',
    padding: '6px',
  },
  inputTextArea: {
    width: '96%',
    minHeight: '60px',
  },
  description: {
    color: '#696969',
    fontSize: '12px',
    marginLeft: '6px',
    marginTop: '5px',
    maxWidth: '91%',
  },
  budgetDetails: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 356px 4px 0',
    justifyContent: 'space-between',
    fontSize: '12px',
  },
  budgetDetailsLabels: {
    color: 'rgba(67, 67, 67, 1)',
  },
  budgetItemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '-10px',
  },
  inputItemText: {
    width: '70%',
    marginRight: '12px',
  },
  inputItemPrice: {
    width: '24%',
  },
  borderTest: {
    borderRadius: '4px',
  },
  budgetItemText: {
    color: 'rgba(67, 67, 67, 1)',
    fontSize: '12px',
  },
  totalPrice: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '14px 0 8px',
    padding: '8px 0',
    borderBottom: '1px solid rgba(180, 180, 180, 1)',
    borderTop: '1px solid rgba(180, 180, 180, 1)',
    textAlign: 'center',
    width: '96%',
  },
  totalPriceValue: {
    fontSize: '16px',
    marginLeft: '8px',
  },

  hrline: {
    display: 'block',
    width: '547',
    border: '1px solid #E3E3E3',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    marginTop: '10px',
    marginBottom: '10px',
  },

  title: {
    marginLeft: '6px',
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#434343',
  },

  installmentContainer: {
    display: 'flex',
    margin: '16px 220px 12px 0',
  },
  installmentLabelContainer: {
    marginTop: '20px',
  },
  inputInstallmentsText: {
    marginTop: '5px',
    color: '#696969',
    fontSize: '12px',
    maxHeight: '300px',
  },
  otherInfosContainer: {
    marginLeft: '155px',
  },
  otherInfosInput: {
    width: '140%',
    marginRight: '80px',
  },
  hidden: {
    opacity: '0',
  },
  attachmentContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  attachmentImage: {
    width: '46%',
    height: 'auto',
    marginBottom: '10px',
  },
  agencyLogo: {
    marginTop: '60px',
    marginLeft: '500px',
    width: '48px',
    height: '40px',
    position: 'absolute',
  },
  headerText: {
    color: '#696969',
    fontSize: '12px',
    marginTop: '6px',
    marginBottom: '18px',
  },
  blueText: {
    color: '#1671ED',
    marginBottom: '20px',
    fontWeight: 'bold',
    fontSize: '12px',
    marginTop: '1%',
    marginLeft: '6px',
  },
  pageSpace: {
    marginLeft: '19px',
  },
  backgroundText: {
    backgroundColor: '#ECF4FE',
    borderRadius: '4px',
    width: '531px',
    height: '25px',
    marginBottom: '5px',
  },
  descriptionText: {
    marginBottom: '30px',
    marginTop: '-15px',
  },
  serviceData: {
    marginTop: '10px',
    marginBottom: '25px',
  },
  lastText: {
    marginBottom: '25px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    marginLeft: '92%',
    color: 'grey',
  },
  tableContainer: {
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
    color: '#434343',
    marginRight: '44px',
    marginTop: '10px',
  },
  rowContainer: {
    borderBottom: '1px solid #E3E3E3',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#696969',
  },
  rowValues: {
    padding: '8px 8px',
    borderLeft: '1px solid #E3E3E3',
    width: '100px',
  },
  rowDescription: {
    padding: '8px 8px',
    flex: '1',
    flexWrap: 'wrap',
  },
  rowValuesTotal: {
    padding: '8px 8px',
    borderLeft: '1px solid #E3E3E3',
    width: '100px',
  },
  rowDescriptionTotal: {
    padding: '8px 8px',
  },
  gray80: {
    color: '#434343',
  },
  gray100: {
    color: '#141414',
  },
  totalBackground: {
    backgroundColor: '#F6F6F6',
  },
  gray32: {
    color: '#B4B4B4',
  },
  titleUnbreakable: {
    marginTop: '20%',
  },
  finalValuesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  referaValueContainer: {
    fontSize: '6px',
    margin: '24px 0',
    marginRight: '24px',
    marginTop: '2%',
    color: '#B4B4B4',
  },

  blackText: {
    color: '#000',
    marginBottom: '20px',
    fontWeight: 'bold',
    fontSize: '12px',
    marginTop: '1%',
    marginLeft: '6px',
  },
  grayBackground: {
    backgroundColor: '#7D7D7D',
    borderRadius: '4px',
    width: '531px',
    height: '25px',
    marginBottom: '5px',
  },
  grayText: {
    color: '#7D7D7D',
  },
  neutralBackground: {
    display: 'flex',
    height: '60px',
    width: '100%',
    margin: '-16px -10px 16px',
    backgroundColor: '#7D7D7D',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
})

export default styles
